<template>
  <v-card
    elevation="6"
    style="text-align: center; border: 1px var(--v-primary-base) solid"
  >
    <v-card-title
      class="titulo no-wrap mx-auto d-block"
      style="max-width: 200px"
      v-if="user.first_name"
    >
      {{ user.first_name }} {{ user.last_name }}
    </v-card-title>
    <v-card-title class="titulo error--text mx-auto d-block" v-else
      >PENDIENTE</v-card-title
    >
    <v-card-text class="content">
      <div
        class="mb-5 pointer no-wrap mx-auto"
        color="whiteprimary"
        style="max-width: 180px"
        @click="$router.push({ name: 'email', params: { email: user.email } })"
      >
        {{ user.email }}
      </div>

      <div
        outlined
        dense
        class="role"
        style="margin-bottom: 10px"
        @click="
          $store.getters['auth/isAdmin'] || $store.getters['auth/isStudio']
            ? (dialog = true)
            : (dialog = false)
        "
      >
        <span style="z-index: 2 !important">
          {{ $tc(user.role.name) }}
        </span>
      </div>
    </v-card-text>
    <v-dialog v-model="dialog" width="300">
      <v-card class="popupCard" style="overflow: hidden; font-size: 12px">
        <v-card-title class="headline"
          >{{ user.first_name }} {{ user.last_name }}
        </v-card-title>
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <form>
          <v-card-text v-if="edit">
            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <label>{{ $t("first_name") }}</label>
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="userEdit.first_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <label>{{ $t("last_name") }}</label>
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="userEdit.last_name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <label>{{ $t("phone") }}</label>
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="userEdit.phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <label>
                  {{ $tc("role") }}
                </label>
                <v-select
                  :items="roleList"
                  v-model="userEdit.role_id"
                  item-value="id"
                  item-text="name"
                  :disabled="!edit"
                  dense
                  hide-details
                  outlined
                  style="width: 110%; border-radius: 25px; margin-bottom: -20px"
                >
                </v-select>
              </v-col>
              <v-col cols="12" class="pt-5 py-0">
                <label>{{ $t("email") }}</label>
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="userEdit.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <label>¿Puede ver otras cajas?</label>
                <v-select
                  outlined
                  dense
                  hide-details
                  v-model="userEdit.can_see_cash_registers"
                  :items="$store.getters['app/yesNo']"
                ></v-select>
              </v-col>
              <v-col cols="12" class="py-0">
                <label>Cajas</label>
                <v-select
                  outlined
                  dense
                  hide-details
                  v-model="userEdit.access_to_cash_registers"
                  :items="cashRegisters"
                  item-text="name"
                  item-value="id"
                  multiple
                  :disabled="userEdit.can_see_cash_registers != true"
                ></v-select>
              </v-col>
              <v-col cols="12" class="py-0">
                <label>¿Puede editar las cajas?</label>
                <v-select
                  outlined
                  dense
                  hide-details
                  v-model="userEdit.can_edit_cash_registers"
                  :items="$store.getters['app/yesNo']"
                  :disabled="userEdit.can_see_cash_registers != true"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <div v-else>
            <div class="divPopup email mt-8">
              <v-row>
                <v-col style="color: white !important" class="no-wrap">
                  <v-icon small color="primary">$email</v-icon> {{ user.email }}
                </v-col>
              </v-row>
            </div>
            <br />

            <div class="divPopup phone">
              <v-row>
                <v-col
                  ><v-icon small color="primary">$phone</v-icon>
                  {{ user.phone || "-" }}</v-col
                >
              </v-row>
            </div>
            <br />
            <div class="divPopup phone">
              <v-row>
                <v-col
                  ><v-icon small color="primary">$customers</v-icon>
                  {{ $tc(user.role.name) }}
                </v-col>
              </v-row>
            </div>

            <br /><br />
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <template v-if="edit">
              <v-btn
                outlined
                @click="
                  edit = false;
                  reload();
                "
                style="height: 25px; width: 100px"
                elevation="0"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                class="mr-0 ml-1"
                rounded
                elevation="0"
                style="height: 25px; width: 100px !important"
                color="primary"
                @click="updateUser"
                ><v-icon style="margin-right: 5px" size="14px">$save</v-icon
                >{{ $t("save", { name: $t("") }) }}</v-btn
              >
            </template>
            <template v-else>
              <v-btn
                outlined
                dense
                style="height: 30px"
                color="red"
                v-if="user.role_id !== 2 && user.role_id !== 8"
                @click="del"
              >
                <v-icon size="14" class="mr-1">$delete</v-icon
                >{{ $t("delete", { name: "" }) }}
              </v-btn>
              <v-btn
                outlined
                dense
                style="height: 30px"
                color="red"
                v-else
                @click="deactivate"
              >
                <v-icon size="14" class="mr-1">mdi-close-circle-outline</v-icon
                >{{ $t("deactivate", { name: "" }) }}
              </v-btn>
              <v-btn
                class="mr-0"
                rounded
                elevation="0"
                style="height: 25px; width: 100px !important"
                color="primary"
                @click="
                  edit = true;
                  reload();
                "
                ><v-icon style="margin-right: 5px" size="14px">$edit</v-icon
                >{{ $t("edit", { name: $t("") }) }}</v-btn
              >
            </template>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import constants from "@/constants";
import { mapActions } from "vuex";

export default {
  name: "UserCard",
  props: ["user"],
  data() {
    return {
      dialog: false,
      edit: false,
      userEdit: {},
      cashRegisters: [],
    };
  },
  mounted() {
    this.reload();
  },
  computed: {
    roleList() {
      if (this.$store.getters["auth/isStudio"]) {
        if (this.user.role.id === constants.roles.TATTOOER)
          return [
            {
              name: this.$tc("artist"),
              id: constants.roles.TATTOOER,
            },
          ];
        return [
          {
            name: this.$tc("studio_admin"),
            id: constants.roles.STUDIO_ADMIN,
          },
          {
            name: this.$tc("studio_manager"),
            id: constants.roles.STUDIO_MANAGER,
          },
        ];
      }
      return [];
    },
    constants() {
      return constants;
    },
  },
  methods: {
    ...mapActions("cash_register", [
      "getCashRegisters",
      "getDefaultCashRegister",
    ]),
    reload() {
      this.userEdit = {
        phone: this.user.phone,
        email: this.user.email,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        role_id: this.user.role.id,
        can_see_cash_registers: this.user.can_see_cash_registers == 1,
        can_edit_cash_registers: this.user.can_edit_cash_registers == 1,
        access_to_cash_registers: this.user.access_to_cash_registers || [],
      };
      this.fetchCashRegisters();
    },
    fetchCashRegisters() {
      this.getCashRegisters({ paginator: { itemPerPage: -1 } }).then(
        (response) => {
          console.log("cash registers", response);
          this.cashRegisters = response.data;
        }
      );
    },
    ...mapActions("users", ["editUser", "deleteUser"]),
    updateUser() {
      this.editUser({
        user_id: this.user.id,
        user: this.userEdit,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("user_edit_success"), "", "");

          this.user.phone = this.userEdit.phone;
          this.user.email = this.userEdit.email;
          this.user.first_name = this.userEdit.first_name;
          this.user.last_name = this.userEdit.last_name;
          this.user.role_id = this.userEdit.role_id;
          this.edit = false;
        }
      });
    },
    del() {
      this.$confirm("¿Seguro que quieres eliminar este usuario?", "", "").then(
        () => {
          this.deleteUser(this.user.id).then(() => {
            this.$alert("Usuario eliminado");
            this.$emit("update");
            this.dialog = false;
          });
        }
      );
    },
    deactivate() {
      this.$confirm(
        "Para desactivar este artista debes ir al apartado de Artistas > Acciones",
        "",
        ""
      ).then(() => {
        this.$router.push({ name: "tattooerList" });
      });
    },
  },
};
</script>
<style scoped>
.titulo {
  font-size: 1.5em;
  color: var(--v-primary-base);
  margin-top: 1em;
  text-transform: uppercase;
  font-weight: bold;
}

.role {
  border: 1px solid var(--v-primary-base);
  border-radius: 1em;
  margin: 0 auto;
  cursor: pointer;
  color: var(--v-primary-base);
  width: 75%;
  margin-bottom: 1em;
  padding: 5px;
}

.divPopup {
  border: 1px solid var(--v-primary-base);
  border-radius: 25px;
  margin: 0 auto;

  width: 90%;
  padding-left: 20px;
}

.email,
.phone {
  height: 30px;
}

.email .col,
.phone .col {
  margin-top: -8px;
}

.color {
  color: var(--v-primary-base) !important;
}
/*
@media only screen and (max-width: 1905px) {
  .titulo {
    font-size: 1em;
    color: var(--v-primary-base);
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }

  .role {
    border: 1px solid var(--v-primary-base);
    border-radius: 1em;
    font-size: 1em;

    margin-bottom: 0.5em;
    padding: 2px;
  }

  .content {
    font-size: 0.8em;
    padding-top: 2px;
  }
}*/
</style>
<style lang="sass">
.popupCard
  .close .v-btn
    top: 0
    right: 0px
    position: absolute

    .v-icon
      color: var(--v-primary-base) !important
      position: absolute
</style>
